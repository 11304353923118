<template>
  <div class="row-center-center" >
    <button v-if="uid" class="btn" type="primary" @click="participate">Participate</button>
    <button v-else class="btn" type="primary" @click="download">Download to Participate</button>
    <el-dialog
      custom-class="my-dialog-name"
      width="90%"
      v-model="open"
      title="Select your Story"
      :append-to-body="true"
      :close-on-press-escape="false"
    >
      <div style="text-align: left">
        <span style="color: #000000;opacity: 0.5; word-break: keep-all;">Published during July 5, 2022 ~ Oct 4, 2022</span>
      </div>

      <div style="text-align: left;margin-top: 20px">
        <span class="dialog-qualified">Qualified Stories</span>
        <el-checkbox-group v-model="gindexList">
        <div v-for="item in tableList" v-loading="tableLoading" >
          <div class="row-left-between item">
            <div class="row-left">
              <img class="img" :src="item.qcGameSummary.thumb">
              <div style="margin-left: 10px;width: 60%">
                <div style="margin-top: 10px;">
                  <span class="gname">{{item.qcGameSummary.gname}}</span>
                </div>
                <div style="margin-top: 5px;height:55px;">
                  <span class="simple sp">{{item.qcGameSummary.descriptionShort}}</span>
                </div>
                <div  v-if="item.participateFlag==1" style="margin-top: 5px;text-align: center;background: #FF9BC9;border-radius: 99px;width: 80px;font-size: 12px;color: white;padding: 4px 10px;" >
                  Submitted
                </div>
              </div>
            </div>
            <div class="row-center-center" >
              <div   v-if="item.participateFlag==0">
                <el-checkbox style="width: 15px;"  :label="item.qcGameSummary.gindex"><br></el-checkbox>
              </div>
              <div   v-if="item.participateFlag==1">
                <img style="width: 15px;" src="@/assets/Vector1.png">
              </div>
            </div>
          </div>
        </div>
        </el-checkbox-group>
        <div v-if="tableList.length==0"  class="item" style="text-align: center;margin-top: 80px">
            <div style="font-size: 16px;font-weight: bold">No stories yet</div>
            <div style="font-size: 12px;font-weight: bold;color: black;opacity: 0.3;margin-top: 5px;word-break: keep-all;">Use StoryMaker to create and publish at once!</div>
        </div>
      </div>

      <template #footer>

        <div class="row-center-center" v-if="gindexList.length>0">
          <button  v-loading="submitLoading" class="confirm" type="primary" @click="confirm"
          >Confirm</button
          >
        </div>

      </template>
    </el-dialog>
  </div>
</template>

<script>

  import axios from 'axios'
  import qs from 'qs'

export default {
  name: 'BottomComponent',

  data() {
    return {
      open:false,
      tableLoading :false,
      tableList :[],
      uid:null,
      platform:null,
      radio:null,
      submitLoading:false,
      gindexList:[]
    }
  },
  methods: {
    participate(){
      this.open=true;
      this.getList();
    },
    getList(){
      if(this.uid===null){
        return ;
      }
      axios.get('https://api-admin.pinkverse.app/admin/open/creator/contest/story/list', {
        params:{
          authorUid:this.uid,
        }
      }).then(res=>{
        let data=res.data;
        this.tableLoading = false;
        this.tableCount = data.total;
        this.tableList =data.rows;

        console.log("tableList:",this.tableList)
      })
    },
    confirm(){
      this.submitLoading=true;
      axios.post('https://api-admin.pinkverse.app/admin/open/creator/contest/2022/summer/participate',{
        gindexList:this.gindexList,
      }).then(res=>{
        this.$message({
          message: 'Success! Your story will be listed in the competition page',
          type: 'success'
        });
        this.submitLoading=false;
        this.open=false;
      })
    },
    download(){
      if(this.platform==='Android'){
        window.open('https://play.google.com/store/apps/details?id=com.imaginelabs.pinkverse')
      }else if(this.platform==='ios'){
        window.open('https://itunes.apple.com/us/app/id1619034910?mt=8')
      }else{
        window.open('https://pinkverse.app/')
      }

    }
  },
  mounted(){
    let query = qs.parse(location.search.substring(1));
    this.uid=query.uid;
     if (Boolean(navigator.userAgent.match(/android/ig))) {
      this.platform='Android';
    } else if (Boolean(navigator.userAgent.match(/iphone|ipod|iOS/ig))){
      this.platform='ios';
    }else {
      this.platform='other';
    }

  },
  created(){

  }
}
</script>

<style lang="scss">
  .my-dialog-name{
     max-width: 380px;
    word-break: keep-all;
    .el-dialog__header{
       text-align: left;
      .el-dialog__title{
        font-size: 20px;
        font-weight: bold;
      }
      .el-dialog__body {
        word-break: keep-all;
      }
      .el-dialog__footer {
        text-align: center;
      }
    }
  }
</style>

<style scoped>

   .btn{
     width: 90%;
     background: linear-gradient(91.13deg, #FF83CF 0%, #9C79FF 100%);
     border-radius: 8px;
     height: 48px;
     font-size: 14px;
     color: white;
     border: none;
   }

  .confirm{
    width: 95%;
    text-align: center;
    background: linear-gradient(91.13deg, #FF83CF 0%, #9C79FF 100%);
    border-radius: 8px;
    color: white;
    border: none;
    height: 48px;
  }
  .dialog-qualified{
    font-weight: bold;
    font-size: 13px;
    color: #000000;
    opacity: 0.7;
  }
   .item{
     padding: 10px;
   }
   .img{
     width: 80px;
     height: 120px;
     border-radius: 8px;
   }
   .gname{
     font-style: normal;
     font-weight: bold;
     font-size: 18px;
     color: #000000;
     word-break: keep-all;
   }
   .simple{
     font-size: 12px;
     color: #000000;
     opacity: 0.7;
     word-break: keep-all;
   }
   .info{
     font-size: 12px;
     line-height: 12px;
     color: rgba(0, 0, 0, 0.8);
     opacity: 0.5;
   }
   .sp {
     display: -webkit-box;
     -webkit-line-clamp: 3;
     -webkit-box-orient: vertical;
     overflow: hidden;
   }

</style>
