<template>

 <div style="background-color: white;">
   <img class="image" src="@/assets/H001.jpg"/>
   <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
     <el-tab-pane label="ABOUT"   name="ABOUT">
       <about-component style="width: 100%"></about-component>
     </el-tab-pane>
     <el-tab-pane   label="RANKING"  name="RANKING">
       <rank-component style="width: 100%" v-if="rankShow"></rank-component>
     </el-tab-pane>
   </el-tabs>
 </div>


</template>

<script>

  import axios from 'axios'
  import qs from 'qs'
  import AboutComponent from "@/components/AboutComponent.vue"
  import RankComponent from "@/components/RankComponent.vue"

export default {
  name: 'HotView',
  components: {
    AboutComponent,RankComponent
  },
  data() {
    return {
      activeName:'ABOUT',
      rankShow:false,
    }
  },
  methods:{
    handleClick(){
      if(this.activeName=='RANKING'){
        this.rankShow=true;
      }
    }
  },

  mounted(){

  },
  created(){

  }

}
</script>
<style scoped lang="scss">

  ::v-deep .el-tabs__header{
    margin: 0;
  }
  .image {
    max-width: 420px;
    width: 100%
  }

</style>
