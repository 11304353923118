<template>
  <div class="row-center-center" >
    <div  class="top1 row-center-center">
      <div style="width: 100px;height: 110px"></div>
      <img class="image-in" src="@/assets/H012.png" @click="handleClick(1)"/>
      <img class="image-in" src="@/assets/H011.png" @click="handleClick(2)"/>
      <img class="image-in" src="@/assets/H010.png" @click="handleClick(3)"/>
    </div>
    <img class="image" src="@/assets/H003.jpg"/>
    <img class="image" src="@/assets/H004.jpg"/>
    <img class="image" src="@/assets/H005.png"/>

    <logo-component style="margin-top: 50px"></logo-component>

    <div style="width: 100px;height: 80px"></div>
    <el-dialog
      custom-class="app-dialog-name"
      v-model="open"
      width="90%"
     >
      <div v-if="open&&content==1" class="cbg">
        <div style="height: 60px;width: 100px"><br></div>
        <div    class="content">
          You are running in the dark forest, behind the chase of the beast, and you desperately want to escape.
          <br>
          <br>
          Help!
          <br>
          <br>
          You are blown to the ground by the branches, and what you see is the beast coming towards you. You close your eyes, and accept the fate of the arrangement.
          <br>
          <br>
          Suddenly a flash of white light in front of you–a gray figure is flying from your side.
          <br>
          <br>
          With sharp fangs and green eyes, the sudden visitor drove away the beast that attacked you. But you know, you're in bigger trouble because this is a wolf.
          <br>
          <br>
          Step by step, he came to you, yet you can not retreat.
          <br>
          <br>
          Suddenly, he transforms into a human form, seriously, that is really a tall and handsome man.
          <br>
          <br>
          He gets down on one knee, puts your shoes on, and gently says, “My Omega, I have come to take you home.”
          <br>
          <br>
          You wake up again, and find yourself still in the familiar apartment. You take a deep breathe. Thanks God! It is just a dream.
          <br>
          <br>
          It's just that this dream happens so often.
          <br>
          <br>
          You can't remember how many times you dream about it.
          <br>
          <br>
          You get on the bus to the company as usual.
          <br>
          <br>
          As soon as you get to the office, you hear your colleagues whispering, and from their conversations you hear that your company will have a new CEO, and that  new CEO is coming to your department meeting this afternoon.
          <br>
          <br>
          You take a deep breath, for this afternoon is your report time.
          <br>
          <br>
          At three o'clock in the afternoon, you come to the conference room ahead of time to get ready.
          <br>
          <br>
          At half past three, the door to the conference room opens and you see the CEO walks in, surrounded by a crowd.
          <br>
          <br>
          Your eyes are widely open-the new CEO's face is exactly the werewolf's face that appears countless times in your dream.
          <br>
          <br>
          His eyes are deep and calm, when he sees you with a tender smile.
          <br>
          <br>
          He comes to you and whispered in your ear, "Long time no see..."
          <br>
          <br>
        </div>
      </div>
      <div v-if="open&&content==2" class="cbg2">
        <div style="height: 60px;width: 100px"><br></div>
        <div  class="content">
          At night, after a tiring day, you arrive at the Bright Lights, Big City's red-light district, which runs the city's largest BDSM club, where you can choose your favorite slave or submit to your master.
          <br>
          <br>
          You are the President of a Security company, heavy work pressure let you out of breath, only here, you can release your nature.
          <br>
          <br>
          Choose the role you want to play:
          <br>
          <br>
          A. Slave  <span style="padding-left: 30px">B. Master</span>
          <br>
          <br>
        </div>
      </div>
      <div v-if="open&&content==3" class="cbg3">
        <div style="height: 60px;width: 100px"><br></div>
        <div  class="content">
          A virus swept the world, which caused 90% of the world's humans extincted.
          <br>
          <br>
          You were born into a poverty-stricken, end-of-the-world slum, where your mother had to become a stripper to survive.
          <br>
          <br>
          You grew up in a ballroom and became a dancer when you were young. You don't know who your father is, and you never thought to look for him. After all,  surviving in the apocalypse is a luxury.
          <br>
          <br>
          As time goes by, you gradually bloomed into a peerless beauty. One night, you were took to the boss's room after work, where you saw a handsome middle-aged man.
          <br>
          <br>
          The boss tells you that you belong to him from now on.
          <br>
          <br>
          That night you were all dressed up and ready to devote yourself to the man, but he didn't have any sex action with you.
          <br>
          <br>
          He taught you to read and write, he taught you manners, everything was out of step with the end times.
          <br>
          <br>
          One day, you come home and see a drunk man lying on the couch. You look closer and see his handsome face.
          <br>
          <br>
          Thinking back to the days when you were by his side，you slowly kneel down, gently kissed the man's lip.
          <br>
          <br>
        </div>
      </div>
      <div style="height: 20px;width: 100px"><br></div>
    </el-dialog>
  </div>
</template>

<script>

import LogoComponent from "@/components/LogoComponent.vue"

export default {
  name: 'AboutComponent',
  components: {
    LogoComponent
  },
  data() {
    return {
      open:false,
      title:'',
      content:1,
    }
  },
  methods:{
    handleClick(n){
      this.open=true;
      if(n==1){
      this.title="Fall In love with my werewolf CEO";
      }
      this.content=n;
    }
  },
}
</script>

<style lang="scss">
  .app-dialog-name{
     max-width: 380px;
     word-break: keep-all;
    .el-dialog__header{
      text-align: left;
      .el-dialog__title{
        font-size: 20px;
        font-weight: bold;
      }
      .el-dialog__body {
        padding: 0;
        margin: 0;
      }
      .el-dialog__footer {
        text-align: center;
      }
    }
  }
</style>

<style scoped>


  .image {
    width: 100%;
  }
  .image-in {
    width: 90%;
    margin-left: 7px;
  }

  .cbg{
    margin-left: -10px;
    background: url("@/assets/D001.png") no-repeat;
    background-size: 100% 460px;
  }
  .cbg2{
    margin-left: -10px;
    background: url("@/assets/D002.png") no-repeat;
    background-size: 100% 460px;
  }
  .cbg3{
    margin-left: -10px;
    background: url("@/assets/D003.png") no-repeat;
    background-size: 100% 460px;
  }
  .content{
    text-align: left;
    white-space: pre-wrap;
    word-break: keep-all;
    overflow-y:scroll;
    height: 400px;
    padding-bottom: -10px;
    padding-left: 15px;
    padding-right: 10px;
  }
  .top1{
    width: 100%;
    background: url("@/assets/H0000.jpg") 100%/cover;
  }

  .title1{
    text-align: center;font-weight: bold;font-size: 20px;    word-break: keep-all;
  }
  .title2{
    text-align: center;padding-bottom: 30px;font-weight: bold;font-size: 16px;padding-top: 5px  ;  word-break: keep-all;
  }
</style>
