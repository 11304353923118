import { createRouter, createWebHistory } from 'vue-router'
import HotView from '../views/HotView.vue'
const routes = [
  {
    path: '/',
    name: 'purchase',
    component: HotView
  }

]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

export default router
