<template>
    <div style="padding-top: 20px">
      <div class="row-center-center">
      <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true" style="width: 200px">
        <el-tab-pane label="Hot"   name="Hot">
        </el-tab-pane>
        <el-tab-pane label="Updated"  name="Updated">
        </el-tab-pane>
      </el-tabs>
      </div>
      <div style="width: 100%;padding-right: 5px;padding-top: 20px" v-if="tableList.length>0" >
        <div class="content" >
          <div v-for="(item,index) in tableList" :key="index" class="game-item">
               <el-divider v-if="index>0"/>
                <div class="row-left" @click="call(item)">
                  <img class="img" :src="item.qcGameSummary.thumb">
                  <span class="index">{{index+1}}</span>
                  <div style="text-align: left;" >
                    <div class="row-left-center">
                      <img  class="avatar" :src="item.avatar"/>
                      <span class="author-name">{{item.qcGameSummary.authorName}}</span>
                    </div>
                    <div style="margin-top: 10px">
                      <span class="gname">{{item.qcGameSummary.gname}}</span>
                    </div>
                    <div style="margin-top: 5px;height: 60px">
                      <span class="simple sp">{{item.qcGameSummary.descriptionShort}}</span>
                    </div>
                    <div>
                      <span class="info" >{{item.qcGameSummary.releaseWordSum}}</span>
                      <span class="info" style="padding-left: 5px">words</span>
                      <el-divider direction="vertical" />
                      <span class="info" style="padding-left: 5px">{{item.visits}}</span>
                      <span class="info" style="padding-left: 5px">reads</span>
                    </div>
                  </div>
                </div>
        </div>
          <logo-component v-if="tableList.length==tableCount" style="margin-top: 50px"></logo-component>
          <div style="height: 100px;width: 10px;"><br></div>
        </div>
      </div>
      <div style="width: 100%;text-align: center;padding-top: 30px" v-if="tableList==null||tableList.length<=0">
        <div style="font-size: 16px;font-weight: bold">No stories yet</div>
        <div style="font-size: 12px;font-weight: bold;color: black;opacity: 0.3;margin-top: 5px">Waiting for your participation!</div>
        <div style="height: 100px;width: 10px;"><br></div>
      </div>

      </div>
</template>

<script>
  import axios from 'axios'
  import qs from 'qs'
  import LogoComponent from "@/components/LogoComponent.vue"
export default {
  name: 'RankComponent',
  components: {LogoComponent
  },
  computed:{

  },
  data() {
    return {
      open:false,
      activeName:'Hot',
      tableCount:0,
      // 表格数据列表
      tableList:[],
      tableLoading:false,
      page:1,
      timer:null,
      platform:'',
    }
  },
  methods:{

    call(item){
      console.log("item.qcGameSummary.gindex:",item.qcGameSummary.gindex)
      if(this.platform==='Android'){
        try{
          pinkverse.openStoryDetailPage(item.qcGameSummary.gindex);
        }catch (e){
          console.log("pinkverse android not  found")
        }
      }else if(this.platform==='ios'){
        try{
          window.webkit.messageHandlers.openStoryDetailPage.postMessage({
            key: "targetValue",
            data: item.qcGameSummary.gindex,
          });
        }catch (e){
          console.log("pinkverse  ios not  found")
        }
      }else{
      }
    },
    handleClick(){
      console.log("handleClick:",this.activeName)
      this.tableList=[];
      this.page=1;
      this.tableLoading=false;
      this.tableCount=0;
      this.loadMore()
    },


    loadMore() {
      if(this.tableLoading){
        return;
      }
      this.tableLoading = true;
      axios.get('/admin/open/creator/contest/2022/summer/'+this.activeName.toLowerCase()+'/list', {
        params:{
          pageNum: this.page++,
          pageSize: 10,
        }
      }).then(res=>{
        let data=res.data;
        this.tableLoading = false;
        this.tableCount = data.total;
        this.tableList = this.tableList.concat(data.rows);
        this.tableList=JSON.parse(JSON.stringify(this.tableList));
        console.log("tableList length:",this.tableList.length)
        if(this.timer!==null){
          clearInterval(this.timer)
          this.timer=null;
        }

      })
    },

  },
  created() {
    const  _this=this;
    window.addEventListener('scroll', function(){
      // topHeight就是滚动条超出顶部的部分高度
      let topHeight = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      //documentHeight 为文档流的高度
      let documentHeight = document.documentElement.clientHeight
      //bodyHeight 窗口的可视高度
      let bodyHeight=document.body.clientHeight
      if(topHeight + bodyHeight >= documentHeight){
        if( _this.timer===null){
          _this.timer = setInterval(()=>{
            _this.loadMore();
          },500)
        }
      }
    })
  },
  mounted(){
    if (Boolean(navigator.userAgent.match(/android/ig))) {
      this.platform='Android';
    } else if (Boolean(navigator.userAgent.match(/iphone|ipod|iOS/ig))){
      this.platform='ios';
    }else {
      this.platform='other';
    }
    this.loadMore();
  }
}
</script>

<style scoped>
  .content{
  }
.game-item{
  padding: 10px;
}
.img{
  width: 110px;
  border-radius: 8px;
}
  .index{
    font-weight: bold;
    font-size: 16px;
    line-height:16px ;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
.avatar{
  width: 32px;height: 32px;border-radius: 50%;
}
  .author-name{
    font-size: 12px;
    line-height: 18px;
    padding-left: 5px;
    color: #000000;
    opacity: 0.5;
  }
  .gname{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    word-break: keep-all;
  }
  .simple{
    font-size: 12px;
    color: #000000;
    opacity: 0.7;
    word-break: keep-all;
  }
  .info{
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.8);
    opacity: 0.5;
  }
 .sp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


</style>
