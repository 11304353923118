<template>
    <div id="app" class="row-center-center"  >
      <div style=" max-width: 420px;width: 100%">
        <div class="row-center-center">
          <div class="router_body">
            <router-view />
          </div>
        </div>
        <div style="position:fixed;bottom:10px;max-width: 420px;width: 100%;">
          <bottom-component ></bottom-component>
        </div>
      </div>
    </div>

</template>

<script>
  import BottomComponent from "@/components/BottomComponent.vue"
export default {
  name: 'App',
  components: {BottomComponent}
}
</script>

<style scoped lang="scss">
  ::v-deep .el-container{height:100%;padding:0;margin:0;width:100%;}
</style>
<style>
#app,body{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0;
  /*外部间距也是如此设置*/
  margin: 0;
  /*统一设置高度为100%*/
  height: 100%;
  width: 100%;
  word-break: keep-all;
}
img{
  display:block;
  margin: 0;
  padding: 0;
  object-fit: contain;
}
.row-left {
  display: flex;
  flex-direction: row;
}
.row-left-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row-center-center {
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
}
.row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.row-column-center {
  display: flex;
  justify-content:center;
  flex-direction:column;
  align-items: center;
}

.row-left-between {
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.row-top-between {
  display: flex;
  justify-content:space-between;
}

</style>
