<template>

  <div class="row-center" style="height:80px;width: 100%">
    <div class="row-left-center" >
      <img class="image" src="@/assets/pinkverse_logo@3x.png"/>
      <div class="header" style="width: 100%;margin-left: 20px">
        Pinkverse
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'LogoComponent',
}
</script>

<style scoped>

  .image {
    width: 50px;
  }
  .header{
    width: 112px;
    height: 18px;

    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 18px;
    /* identical to box height, or 82% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #A87AF9;
  }

</style>
